<template>
  <div class="searchbox-container" :class="isSearchFocus ? 'focus' : ''" @click="focus">
    <span class="material-icons" style="color: #0000008a; padding-right: 20px">search</span>
    <input class="search-input" placeholder="Search for a product" ref="inputRef" @blur="blur" v-model="keyWord" @input="searchThis()" />
  </div>
</template>

<script>
import { ref } from 'vue';
import router from '@/router';

export default {
  setup() {
    const inputRef = ref(null);
    const isSearchFocus = ref(false);
    const keyWord = ref('');

    const focus = () => {
      inputRef.value.focus();
      isSearchFocus.value = true;
    };

    const blur = () => {
      isSearchFocus.value = false;
    };

    const searchThis = () => {
      if (keyWord.value) {
        router.push(`/products/search/${keyWord.value}`);
      } else {
        router.push('/products');
      }
    };

    return {
      inputRef,
      focus,
      blur,
      isSearchFocus,
      keyWord,
      searchThis,
    };
  },
};
</script>

<style lang="scss" scoped>
.searchbox-container {
  display: flex;
  padding: 5px 20px;
  width: 100%;
  border: 1px solid #00000047;
  border-radius: 5px;
  align-items: center;

  .search-input {
    padding: 0;
    border: unset !important;
  }
}

.searchbox-container.focus {
  border: 1px solid #000;

  .search-input {
    padding: 0;
    border: unset !important;
  }
}
</style>
