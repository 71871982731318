<template>
  <el-container direction="vertical">
    <Header :handleSidebarShow="handleSidebarShow" :sidebarShow="sidebarShow" :isMobileView="isMobileView" />
    <el-container class="main-container">
      <el-aside class="sidebar-aside">
        <SidebarContainer :sidebarShow="sidebarShow" :handleSidebarShow="handleSidebarShow" :isProfileNav="isProfileNav" />
      </el-aside>
      <div class="app-body">
        <router-view />
      </div>
    </el-container>
    <el-footer>
      <div style="padding: 20px">
        <el-row :gutter="20">
          <el-col :span="12" :lg="6">
            <div class="sub-header" style="margin-bottom: 10px">XMarketplace</div>
          </el-col>
          <el-col :span="12" :lg="6">
            <div class="sub-header to-upper">Licenses</div>
            <div style="margin-top: 10px; margin-bottom: 10px">
              <ul style="list-style-type: none">
                <li class="left-text fs-20" v-for="license in licenses.slice(0, 4)" :key="license">
                  <router-link :to="`/products/license/${license.licenseId}`"> {{ license.name }}</router-link>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12" :lg="6">
            <div class="sub-header to-upper">Series</div>
            <div style="margin-top: 10px; margin-bottom: 10px">
              <ul style="list-style-type: none">
                <li class="left-text fs-20" v-for="data in series.slice(0, 4)" :key="data">
                  {{ data.name }}
                </li>
              </ul>
            </div>
          </el-col>
          <!-- <el-col :span="12" :lg="4">
            <div class="sub-header to-upper">Characters</div>
            <div style="margin-top: 10px; margin-bottom: 10px">
              <ul style="list-style-type: none">
                <li class="left-text fs-20">DC</li>
                <li class="left-text fs-20">Disney</li>
                <li class="left-text fs-20">Hasbro</li>
                <li class="left-text fs-20">Marvel</li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12" :lg="4">
            <div class="sub-header to-upper">Scales</div>
            <div style="margin-top: 10px; margin-bottom: 10px">
              <ul style="list-style-type: none">
                <li class="left-text fs-20">DC</li>
                <li class="left-text fs-20">Disney</li>
                <li class="left-text fs-20">Hasbro</li>
                <li class="left-text fs-20">Marvel</li>
              </ul>
            </div>
          </el-col> -->
          <el-col :span="12" :lg="6">
            <div class="sub-header to-upper">Profile</div>
            <div style="margin-top: 10px; margin-bottom: 10px">
              <ul style="list-style-type: none">
                <li class="left-text fs-20">
                  <router-link to="/profile/listings">Listings</router-link>
                </li>
                <li class="left-text fs-20">
                  <router-link to="/profile/transactions">Transactions</router-link>
                </li>
                <li class="left-text fs-20">
                  <router-link to="/profile/settings">Settings</router-link>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import Header from '@/components/Layout/Header.vue';
import SidebarContainer from '@/components/Layout/SidebarContainer.vue';
import { mapActions, useStore } from 'vuex';
import productServices from '@/services/product-service';

export default {
  components: {
    Header,
    SidebarContainer,
  },
  data() {
    return {
      sidebarShow: false,
      isMobileView: false,
      isProfileNav: false,
      windowWidth: 0,
      timeout: null,
    };
  },
  methods: {
    ...mapActions('layout', {
      updateMobielView: 'UpdateMobileView',
      updateLicense: 'UpdateLicenses',
    }),
    handleSidebarShow(isProfileNavClick) {
      this.isProfileNav = isProfileNavClick;
      this.sidebarShow = !this.sidebarShow;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    windowWidth(newWindowWidth) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (newWindowWidth < '768') {
          this.isMobileView = true;
          this.updateMobielView(true);
        } else {
          this.isMobileView = false;
          this.updateMobielView(false);
          this.sidebarShow = false;
        }
      }, 100);
    },
  },
  setup() {
    const series = ref([]);
    const store = useStore();
    const licenses = ref([]);
    const updateLicense = (license) => store.dispatch('layout/UpdateLicenses', license);

    onMounted(async () => {
      await Promise.all([
        productServices.retrieveAllSeries(),
        productServices.retrievePopularLicenses(),
      ])
        .then((results) => {
          series.value = [...results[0]];
          licenses.value = [...results[1]];
          updateLicense([...results[1]]);
        })
        .catch(() => {});
    });

    return {
      series,
      licenses,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.app-body {
  // display: flex;
  // flex-flow: column;
  // -webkit-box-flex: 1;
  // -ms-flex: 1;
  // flex: 1;
  width: 100%;
}

.main-container {
  // height: calc(100vh - 60px);
  height: 100%;
}

.sidebar-aside {
  width: unset !important;
  height: 100%;
  position: absolute;
  z-index: 101;
}

.body-scrollbar {
  width: 100%;

  @media only screen and (min-width: 993px) {
    // padding-left: 68px;
  }
}

.sidebar-aside,
.el-container.is-vertical {
  background-color: $--color-primary-bg;
}
.el-footer {
  background-color: $--color-secondary;
  // z-index: 1000;
  height: 100% !important;
}

ul {
  padding: 0;
  margin: 0;
}
</style>
