const state = {
  isMobileView: false,
  licenses: [],
};

const getters = {
  popularLicenses(state) {
    return state.licenses.slice(0, 4);
  },
};

const actions = {
  UpdateMobileView({ commit }, isMobileView) {
    commit('isMobileViewUpdate', isMobileView);
  },
  UpdateLicenses({ commit }, licenses) {
    commit('licensesUpdate', licenses);
  },
};

const mutations = {
  isMobileViewUpdate(state, isMobileView) {
    state.isMobileView = isMobileView;
  },
  licensesUpdate(state, licenses) {
    state.licenses = licenses;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
