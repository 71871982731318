<template>
  <GeneralLayout />
</template>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>

<script>
// @ is an alias to /src
import { onMounted } from 'vue';
import GeneralLayout from '@/views/layout/GeneralLayout.vue';
import { useSignalR } from '@quangdao/vue-signalr';
import { getAuthID, isLoggin } from '@/helpers';

export default {
  name: 'App',
  components: {
    GeneralLayout,
  },
  data() {
    return {
      url: '',
    };
  },
  watch: {
    $route() {
      this.url = this.$router.currentRoute.value.fullPath;
    },
  },
  setup() {
    const signalR = useSignalR();

    onMounted(async () => {
      // Subscribe SignalR Hub if user login
      if (isLoggin()) {
        const { userId } = getAuthID();
        signalR.invoke('SubscribeToChatRoom', userId);
      }
    });
  },
};
</script>
