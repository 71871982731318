import { createRouter, createWebHistory } from 'vue-router';
import { nonAuthenticatedRoute, authenticatedRoute } from '@/helpers';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
  },
  {
    path: '/profile',
    beforeEnter: [authenticatedRoute],
    component: () => import(/* webpackChunkName: "home" */ '../views/profile/ProfileLayout.vue'),
    children: [
      {
        name: 'My Listings',
        path: 'listings',
        component: () => import(/* webpackChunkName: "profile" */ '../views/listing/ProfileListings.vue'),
      },
      {
        name: 'My Transactions',
        path: 'transactions',
        beforeEnter: [authenticatedRoute],
        component: () => import(/* webpackChunkName: "profile" */ '../views/transactions/Transactions.vue'),
      },
      {
        name: 'My Reviews',
        path: 'reviews',
        component: () => import(/* webpackChunkName: "profile" */ '../views/review/ProfileReviews.vue'),
      },
      {
        name: 'My Likes',
        path: 'likes',
        component: () => import(/* webpackChunkName: "profile" */ '../views/product/Likes.vue'),
      },
      {
        name: 'Settings',
        path: 'settings',
        component: () => import(/* webpackChunkName: "profile" */ '../views/setting/Settings.vue'),
      },
      {
        name: 'Upload Product',
        path: 'uploadproduct',
        component: () => import(/* webpackChunkName: "profile" */ '../views/product/ProductUpload.vue'),
      },
    ],
  },
  {
    path: '/chat',
    name: 'Chat',
    beforeEnter: [authenticatedRoute],
    component: () => import(/* webpackChunkName: "chat" */ '../views/chat/Chat.vue'),
  },
  {
    path: '/licenses',
    name: 'Licenses',
    component: () => import(/* webpackChunkName: "license" */ '../views/product/AllLicense.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "product" */ '../views/product/AllProduct.vue'),
  },
  {
    path: '/products/license/:id',
    name: 'License Products',
    component: () => import(/* webpackChunkName: "product" */ '../views/product/LicenseProducts.vue'),
  },
  {
    path: '/products/search/:id',
    name: 'Search Products',
    component: () => import(/* webpackChunkName: "product" */ '../views/product/SearchProducts.vue'),
  },
  {
    path: '/product/:id',
    name: 'Product Detail',
    component: () => import(/* webpackChunkName: "product" */ '../views/product/ProductDetail.vue'),
  },
  {
    path: '/payment/:productid/:transactionid',
    name: 'Transaction Payment',
    beforeEnter: [authenticatedRoute],
    component: () => import(/* webpackChunkName: "product" */ '../views/transactions/TransactionPayment.vue'),
  },
  {
    path: '/user/:id',
    component: () => import(/* webpackChunkName: "home" */ '../views/profile/UserProfileLayout.vue'),
    children: [
      {
        name: 'Listings',
        path: 'listings',
        component: () => import(/* webpackChunkName: "user" */ '../views/listing/UserListings.vue'),
      },
      {
        name: 'Reviews',
        path: 'reviews',
        component: () => import(/* webpackChunkName: "user" */ '../views/review/UserReviews.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: [nonAuthenticatedRoute],
    component: () => import(/* webpackChunkName: "auth" */ '../views/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: [authenticatedRoute],
    component: () => import(/* webpackChunkName: "auth" */ '../views/Logout.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'Forgot Password',
    component: () => import(/* webpackChunkName: "auth" */ '../views/ForgotPassword.vue'),
  },
  {
    path: '/resetpassword/:id',
    name: 'Reset Forgot Password',
    beforeEnter: [nonAuthenticatedRoute],
    component: () => import(/* webpackChunkName: "auth" */ '../views/ResetPassword.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    beforeEnter: [nonAuthenticatedRoute],
    component: () => import(/* webpackChunkName: "auth" */ '../views/Signup.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
