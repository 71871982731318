import { MpApiIni, SetAuthHeader } from './api';

function createProfile(profileDetails) {
  return MpApiIni().post('/api/mp/profile/v1/profile', profileDetails)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getProfilebyUserId(userId) {
  return MpApiIni().get(`/api/mp/profile/v1/${userId}/profile/user`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getProfilebyProfileId(profileId) {
  return MpApiIni().get(`/api/mp/profile/v1/${profileId}/profile`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function updateProfile(updatedProfile) {
  return MpApiIni().put(`/api/mp/profile/v1/${updatedProfile.profileId}/profile`, updatedProfile, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function addReview(reviewDetail) {
  return MpApiIni().post('/api/mp/profile/v1/review', reviewDetail, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function checkUserAbleToReview(transctionId) {
  return MpApiIni().get(`/api/mp/profile/v1/review/${transctionId}/checkabletoreview`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getProfileReviews(profileId) {
  return MpApiIni().get(`/api/mp/profile/v1/review/${profileId}`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

const services = {
  createProfile,
  getProfilebyUserId,
  getProfilebyProfileId,
  updateProfile,
  addReview,
  checkUserAbleToReview,
  getProfileReviews,
};

export default services;
