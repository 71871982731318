import { MpApiIni, SetAuthHeader, XMRouterIni } from './api';

function getProductByUserId(userId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/${userId}/product/user`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getProducts() {
  return MpApiIni()
    .get('/api/mp/product/v1/products')
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getProductById(productId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/${productId}/product`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getProvenanceBySerialNumber(serialNumber) {
  return XMRouterIni()
    .get(`/api/xm/product/v1/productprovenance/${serialNumber}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getProductByCharacterId(characterId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/characterproducts/${characterId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getProductByLicenseId(licenseId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/licenseproducts/${licenseId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getSimilarProductsById(productId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/${productId}/product/similar`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function createProduct(productDetails) {
  return MpApiIni()
    .post('/api/mp/product/v1/product', productDetails, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function updateProduct(productId, productDetail) {
  return MpApiIni()
    .put(`/api/mp/product/v1/${productId}/product`, productDetail, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function deleteProduct(productId) {
  return MpApiIni()
    .delete(`/api/mp/product/v1/${productId}/product`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getBannerProducts() {
  return MpApiIni()
    .get('/api/mp/product/v1/products/banner')
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getPopularProducts() {
  return MpApiIni()
    .get('/api/mp/product/v1/products/popular/4')
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getAuctionEndSoonProducts() {
  return MpApiIni()
    .get('/api/mp/product/v1/products/auctionendsoon/4')
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function placeBid(bidDetails) {
  return MpApiIni()
    .post('/api/mp/product/v1/bid', bidDetails, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function placeOffer(offerDetails) {
  return MpApiIni()
    .post('/api/mp/product/v1/offer', offerDetails, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getBidsByProductId(productId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/product/${productId}/bids`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getOffersByProductId(productId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/${productId}/offers`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function rejectOffer(offerId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/${offerId}/rejectoffer`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function acceptOffer(offerId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/${offerId}/acceptoffer`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function cancelOffer(offerId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/${offerId}/canceloffer`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function likeUnlikeProduct(productId) {
  return MpApiIni()
    .post(`/api/mp/product/v1/${productId}/like`, null, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getLikedProduct(productId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/${productId}/like`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getLikedProducts() {
  return MpApiIni()
    .get('/api/mp/product/v1/like/products', SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function removeLikeProducts(body) {
  return MpApiIni()
    .post('/api/mp/product/v1/like/remove', body, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function retrieveAllLicenses() {
  return MpApiIni()
    .get('/api/mp/product/v1/licenses', SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function retrieveCharactersByLicenseId(licenseId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/characters/${licenseId}`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function retrieveAllSeries() {
  return MpApiIni()
    .get('/api/mp/product/v1/series/all')
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function retrieveSeriesByLicenseId(licenseId) {
  return MpApiIni()
    .get(`/api/mp/product/v1/series/${licenseId}`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function retrievePopularLicenses() {
  return MpApiIni()
    .get('/api/mp/product/v1/licenses/popular')
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function searchProduct(productName) {
  return MpApiIni()
    .get(`/api/mp/product/v1/products/search?productName=${productName}`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

function getArtist() {
  return XMRouterIni()
    .get(`/api/xm/artist/v1/artists`, SetAuthHeader())
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

const services = {
  getArtist,
  getProducts,
  getProductByUserId,
  getProductById,
  getProductByCharacterId,
  getProductByLicenseId,
  getSimilarProductsById,
  getBannerProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  getPopularProducts,
  getAuctionEndSoonProducts,
  placeBid,
  placeOffer,
  getBidsByProductId,
  getOffersByProductId,
  acceptOffer,
  rejectOffer,
  cancelOffer,
  likeUnlikeProduct,
  getLikedProducts,
  getLikedProduct,
  removeLikeProducts,
  retrieveAllLicenses,
  retrieveCharactersByLicenseId,
  retrieveAllSeries,
  retrieveSeriesByLicenseId,
  retrievePopularLicenses,
  searchProduct,
  getProvenanceBySerialNumber
};

export default services;
