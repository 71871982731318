<template>
  <el-menu
    class="side-manu"
    :default-active="activeMenu"
    :router="true"
    :collapse="!sidebarShow"
    :unique-opened="true"
  >
    <template v-for="item in navMenu" :key="item.name">
      <template v-if="!item.child">
        <router-link :to="item.route">
          <el-menu-item :index="item.route" @click="closeNavbar" :key="1">
            <i :class="item.icon"></i>
            <template #title>{{ item.name }}</template>
          </el-menu-item>
        </router-link>
      </template>
      <template v-else>
        <el-submenu :index="item.name" class="custom-submenu custom-submenu-title">
          <template #title>
            <i :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-for="nav in item.subNav" :key="nav.name" :index="nav.route">
              <span>{{ nav.name }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </template>
    </template>
  </el-menu>
</template>

<script>
import { PROFILE_NAV_MENU } from '@/common/constants';
import { mapGetters } from 'vuex';
import { isLoggin } from '@/helpers';

export default {
  props: {
    sidebarShow: Boolean,
    handleSidebarShow: Function,
    isProfileNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentRoute: this.$router.currentRoute.value.fullPath,
      activeMenu: null,
      navMenu: [],
      isUserLoggin: isLoggin(),
    };
  },
  computed: {
    ...mapGetters('layout', ['popularLicenses']),
  },
  mounted() {
    this.activeMenu = this.$router.currentRoute.value.fullPath;
    this.currentRoute = this.$router.currentRoute.value.fullPath;
    if (this.isProfileNav) {
      this.navMenu = PROFILE_NAV_MENU.filter((x) => x.needLogin === this.isUserLoggin);
    } else {
      this.navMenu = this.constructNavMenu();
    }
  },
  watch: {
    $route() {
      this.activeMenu = this.$router.currentRoute.value.fullPath;
      this.currentRoute = this.$router.currentRoute.value.fullPath;
    },
    isProfileNav(newIsProfileNav) {
      this.navMenu = newIsProfileNav ? PROFILE_NAV_MENU.filter((x) => x.needLogin === this.isUserLoggin) : this.constructNavMenu();
    },
  },
  methods: {
    closeNavbar() {
      this.handleSidebarShow(this.isProfileNav);
    },
    constructNavMenu() {
      const newNavMenu = [
        {
          name: 'All Products',
          route: '/products',
          child: false,
        },
      ];

      this.popularLicenses.forEach((license) => {
        newNavMenu.push({
          name: license.name,
          route: `/products/license/${license.licenseId}`,
          child: false,
        });
      });

      newNavMenu.push({
        name: 'Others',
        route: '/licenses',
        child: false,
      });

      return newNavMenu;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.el-menu {
  font-weight: bold;
  font-size: 16px;
}

.el-menu-item {
  border-radius: 1px 1px 1px 1px !important;
  text-align: center;
}
</style>
