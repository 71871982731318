<template>
  <section class="header-section">
    <el-header class="category-header" v-if="!isMobileView" height="45px">
      <el-menu
        :default-active="`/`"
        class="el-menu-category fm-montserrat fw-700"
        mode="horizontal"
        text-color="#FFF"
        active-text-color="#000"
      >
        <el-menu-item index="/" class="fs-20">
          <router-link :to="`/products`">All Products</router-link>
        </el-menu-item>
        <el-menu-item
          v-for="license in popularLicenses"
          :key="license.licenseId"
          class="fs-20"
          :index="license.licenseId"
        >
          <router-link :to="`/products/license/${license.licenseId}`">{{ license.name }}</router-link>
        </el-menu-item>
        <el-menu-item index="/" class="fs-20">
          <router-link :to="`/licenses`">Others</router-link>
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-header class="header">
      <div class="primary-header">
        <template v-if="!isMobileView">
          <div class="center-text" style="background: #C4C4C4;">
            <router-link to="/">
              <span class="header-title" style="background: #C4C4C4; word-break: initial;">XMarketplace</span>
            </router-link>
          </div>
          <div style="padding: 0 30px; width: 100%; display: flex;"><SearchBox /></div>
          <div style="display: flex; align-items: center;">
            <router-link to="/chat" v-if="isLoggin" style="line-height: 0;">
              <span class="material-icons" style="margin-right: 20px; cussor: pointer; color: #0000008A;">message</span>
            </router-link>
            <el-dropdown placement="bottom-end">
              <span class="el-dropdown-link">
                <el-image
                  class="circle-image cursor-pointer img-size-35"
                  :src="defaultProfilePic"
                  fit="fill"
                ></el-image>
              </span>
              <template #dropdown>
                <el-dropdown-menu class="header-dropdown-menu to-upper fm-montserrat fw-700">
                  <el-dropdown-item
                    v-for="item in navMenu"
                    :key="item.name"
                    @click="$router.push(item.route)"
                  >
                    <router-link class="fs-24" :to="item.route">{{ item.name }}</router-link>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </template>
        <template v-else>
          <div class="header-name-wrapper">
            <div @click="handleSidebarShow(false)" class="icon-wrapper d-flex">
              <span v-show="!sidebarShow" class="fa fa-bars"></span>
              <span v-show="sidebarShow" class="xm-el-icon-s-left"></span>
              <br />
            </div>
          </div>
          <div class="center-text w-100">
            <router-link to="/">
              <span class="header-title">XMarketplace</span>
            </router-link>
          </div>
          <div style="display: flex; align-items: center;">
            <el-image
              class="circle-image cursor-pointer img-size-35"
              :src="defaultProfilePic"
              fit="fill"
              @click="handleSidebarShow(true)"
            ></el-image>
          </div>
        </template>
      </div>
    </el-header>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { SIGN_OUT } from '@/store/modules/auth/actions-type';
import { isLoggin } from '@/helpers';
import { PROFILE_NAV_MENU } from '@/common/constants';
import SearchBox from '@/components/Search/SearchBox.vue';

export default {
  components: {
    SearchBox,
  },
  props: {
    handleSidebarShow: Function,
    isMobileView: Boolean,
    sidebarShow: Boolean,
  },
  data() {
    return {
      navMenu: PROFILE_NAV_MENU.filter((x) => x.needLogin === isLoggin()),
      defaultProfilePic: process.env.VUE_APP_DEFAULT_PIC_URL,
      isLoggin: isLoggin(),
    };
  },
  computed: {
    ...mapState('auth', ['loginProfile']),
    ...mapGetters('layout', ['popularLicenses']),
  },
  methods: {
    ...mapActions('auth', [SIGN_OUT]),
  },
  mounted() {
    this.defaultProfilePic = isLoggin()
      ? this.loginProfile.displayPhotoFilePath ?? process.env.VUE_APP_DEFAULT_PIC_URL
      : process.env.VUE_APP_DEFAULT_PIC_URL;
  },
  watch: {
    loginProfile(newProfile) {
      if (isLoggin() && newProfile && newProfile.displayPhotoFilePath) {
        this.defaultProfilePic = newProfile.displayPhotoFilePath;
      }
    },
  },
};
</script>

<style lang="scss">
/*
.el-menu.el-menu--horizontal {
    width: 100%;
    margin: auto;
}
*/

.icon-wrapper {
  margin-right: 20px;
}

.icon-wrapper,
.header-action-icon {
  font-size: 25px;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
